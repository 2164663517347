import { lazy } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import LearningResourceDetail from 'pages/LearningResources/components/LearningResourceDetail'

const Home = lazy(() => import('./pages/Home'))
const GreenFuels = lazy(() => import('./pages/GreenFuels'))
const GreenHydrogen = lazy(() => import('./pages/GreenFuels/GreenHydrogen'))
const GreenAmmonia = lazy(() => import('./pages/GreenFuels/GreenAmmonia'))
const Projects = lazy(() => import('./pages/Projects'))
const PointTupper = lazy(() => import('./pages/Projects/PointTupper'))
const BurinPeninsula = lazy(() => import('./pages/Projects/BurinPeninsula'))
const PhaseOnePower = lazy(() => import('./pages/Projects/PhaseOnePower'))
const StraitRegionCommittee = lazy(() => import('./pages/Projects/StraitRegionCommittee'))
const PressRelease = lazy(() => import('./pages/PressRelease'))
const PressReleaseDetail = lazy(() => import('./pages/PressRelease/components/PressReleaseDetail'))
const FAQ = lazy(() => import('./pages/Faq'))
const BurinPeninsulaFAQ = lazy(() => import('./pages/ProjectFaq/BurinPeninsula'))
const GetInTouch = lazy(() => import('./pages/Contact'))
const SupplierRegistration = lazy(() => import('./pages/SupplierRegistration'))
const Teams = lazy(() => import('./pages/Teams'))
const Careers = lazy(() => import('./pages/Careers'))
const WhoWeAre = lazy(() => import('./pages/WhoWeAre'))
const EducationChallenge = lazy(() => import('./pages/EducationChallenge'))
const BurinPeninsulaProject = lazy(() => import('./pages/BurinPeninsula'))
const LearningResources = lazy(() => import('./pages/LearningResources'))

const MainRoutes = () => {
  const location = useLocation()

  return (
    <Routes location={location} key={location.pathname}>
      <Route path={'/'} element={<Home />} />
      <Route path={'/green_fuels'} element={<GreenFuels />}>
        <Route index path={'green_hydrogen'} element={<GreenHydrogen />} />
        <Route path={'green_ammonia'} element={<GreenAmmonia />} />
      </Route>
      <Route path={'/projects'} element={<Projects />}>
        <Route index path={'point_tupper'} element={<PointTupper />} />
        <Route path={'burin_peninsula'} element={<BurinPeninsula />} />
        <Route path={'burin_peninsula/faq'} element={<BurinPeninsulaFAQ />} />
        <Route path={'phase_one_power'} element={<PhaseOnePower />} />
        <Route path={'straight_region_committee'} element={<StraitRegionCommittee />} />
      </Route>
      <Route path={'/news'} element={<PressRelease />}>
        <Route path={':id'} element={<PressReleaseDetail />} />
      </Route>
      <Route path={'/faq'} element={<FAQ />} />
      <Route path={'/get_in_touch'} element={<GetInTouch />} />
      <Route path={'/supplier_registration'} element={<SupplierRegistration />} />
      <Route path={'/teams'} element={<Teams />} />
      <Route path={'/careers'} element={<Careers />} />
      <Route path={'/who_we_are'} element={<WhoWeAre />} />
      <Route path={'/contest'} element={<EducationChallenge />} />
      <Route path={'/burin_Peninsula_project'} element={<BurinPeninsulaProject />} />
      <Route path={'/resources'} element={<LearningResources />}>
        <Route path={':id'} element={<LearningResourceDetail />} />
      </Route>
      <Route path={'/*'} element={<Navigate replace to="/" />} />
    </Routes>
  )
}

export default MainRoutes
