import { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import { PRESS_RELEASE_MEDIA_LIST } from '../dummy'
import './style.css'

const LearningResourceDetail = () => {
  const params = useParams()

  const blog = useMemo(() => {
    const id = params.id
    const current = PRESS_RELEASE_MEDIA_LIST.find((press) => press.id === Number(id))

    return current ?? undefined
  }, [params.id])

  if (!blog) return <></>

  const date = new Date(blog.date).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })
  return (
    <div className="flex flex-col w-full items-start gap-8 py-10 text-start">
      <span className="font-bold text-green text-4xl md:text-[40px] leading-[120%] tracking-[-0.06em]">{blog.title}</span>
      {blog.detail.subTitle && <span className="font-bold text-green/70 text-xl">{blog.detail.subTitle}</span>}
      <div className="flex justify-end w-full">
        {date}
        {', ET'}
      </div>
      {blog.detail.content.map((text: any) => {
        const markup = { __html: text.detail }
        if (text.isImage)
          return (
            <div key={text.detail} className="flex flex-col items-center gap-4 w-full">
              <div
                dangerouslySetInnerHTML={markup}
                className={`${text.isSmallImg ? 'imgSMWrapper' : 'imgWrapper'} w-full flex justify-center`}
              />
              <div className={`${text.isSmallImg ? 'w-[90%] md:w-3/5' : 'w-11/12'} text-center text-sm`}>{text.description}</div>
            </div>
          )
        if (text.hasHTMLString)
          return (
            <span
              key={text.detail}
              className={`detailWrapper whitespace-pre-line ${text.isSubTitle ? 'font-bold text-lg text-green' : ''}`}
              dangerouslySetInnerHTML={markup}
            />
          )
        return (
          <span key={text.detail} className={`detailWrapper whitespace-pre-line ${text.isSubTitle ? 'font-bold text-lg text-green' : ''}`}>
            {text.detail}
          </span>
        )
      })}
    </div>
  )
}

export default LearningResourceDetail
