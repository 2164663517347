import L_RESOURCE_3_Img from 'assets/images/learningResources/certifhy.png'
import L_RESOURCE_8_Img from 'assets/images/learningResources/dalhousie.png'
import L_RESOURCE_5_Img from 'assets/images/learningResources/erm.png'
import L_RESOURCE_9_Img from 'assets/images/learningResources/everwind.png'
import L_RESOURCE_7_Img from 'assets/images/learningResources/hydrogen_council.png'
import L_RESOURCE_1_Img from 'assets/images/learningResources/net_zero.png'
import L_RESOURCE_6_Img from 'assets/images/learningResources/opis.png'
import L_RESOURCE_4_Img from 'assets/images/learningResources/RFNBO_EU_REQUIREMENTS.png'
import L_RESOURCE_1 from 'assets/pdf/learningResources/International-PtX-Hub_EU-Requirements-for-green-hydrogen-and-PtX.pdf'

export interface ILearningResourceData {
  id: number
  type: string
  date: string
  title: string
  url: string
  detail: any
  isExternal?: boolean
  specialId?: string
  imgUrl?: string
}

export const PRESS_RELEASE_MEDIA_LIST: ILearningResourceData[] = [
  {
    id: 10,
    type: 'Hydrogen Mythbusting',
    date: '12/12/2024',
    title: 'Exploring the Potential of Green Hydrogen (Dalhousie)',
    detail: '',
    url: 'https://www.youtube.com/watch?v=ctfQRwFwc0Y',
    isExternal: true,
    imgUrl: L_RESOURCE_8_Img,
  },
  {
    id: 9,
    type: 'Domestic Green Hydrogen Uses',
    date: '12/12/2024',
    title: 'Unlocking the power of green hydrogen (Dalhousie)',
    detail: '',
    url: 'https://www.dal.ca/faculty/engineering/news-events/news/2024/06/13/unlocking_the_power_of_green_hydrogen.html',
    isExternal: true,
    imgUrl: L_RESOURCE_8_Img,
  },
  {
    id: 8,
    type: 'Hydrogen Grid Benefits',
    date: '12/12/2024',
    title: 'Hydrogen in Decarbonized Energy Systems',
    detail: '',
    url: 'https://hydrogencouncil.com/wp-content/uploads/2023/12/Hydrogen-in-Decarbonized-Energy-Systems.pdf',
    isExternal: true,
    imgUrl: L_RESOURCE_7_Img,
  },
  {
    id: 7,
    type: 'Hydrogen Mythbusting',
    date: '12/12/2024',
    title: 'Dispelling Myths around Hydrogen',
    detail: '',
    url: 'https://info.opisnet.com/hubfs/White%20Paper-Dispelling%20Myths%20Hydrogen-Whitepaper-1.pdf',
    isExternal: true,
    imgUrl: L_RESOURCE_6_Img,
  },
  {
    id: 6,
    type: 'Decarbonization Benefits',
    date: '12/12/2024',
    title: 'Reducing CO2 emissions by 95% with EverWind Fuels Green Ammonia',
    detail: '',
    url: 'https://www.erm.com/projects/case-study-tackling-legislation-and-logistics-how-everwind-is-taking-green-hydrogen-to-the-global-market-with-erm/',
    isExternal: true,
    imgUrl: L_RESOURCE_5_Img,
  },
  {
    id: 5,
    type: 'Hydrogen Grid Benefits',
    date: '12/12/2024',
    title: 'Driving Nova Scotia’s Clean Energy Future',
    detail: '',
    url: 'https://www.youtube.com/shorts/Fi_-AJ-0i4M',
    isExternal: true,
    imgUrl: L_RESOURCE_9_Img,
  },
  {
    id: 4,
    type: 'Green Hydrogen Certification',
    date: '11/06/2024',
    title: 'EU REQUIREMENTS FOR RENEWABLE HYDROGEN AND ITS DERIVATIVES',
    detail: '',
    url: L_RESOURCE_1,
    isExternal: true,
    imgUrl: L_RESOURCE_4_Img,
  },
  {
    id: 3,
    type: 'Green Hydrogen Certification',
    date: '10/30/2023',
    title: "EverWind Completes Pre-Certification with CertifHy™ for Canada's First Green Hydrogen and Ammonia Production",
    detail: '',
    url: 'https://www.newswire.ca/news-releases/everwind-completes-pre-certification-with-certifhy-tm-for-canada-s-first-green-hydrogen-and-ammonia-production-831201822.html',
    isExternal: true,
    imgUrl: L_RESOURCE_3_Img,
  },
  {
    id: 2,
    type: 'Green Hydrogen Benefits',
    date: '04/1/2023',
    title: 'Socioeconomic Impacts of Hydrogen Production in Nova Scotia',
    detail: '',
    url: 'https://netzeroatlantic.ca/research/socioeconomic-impacts-hydrogen-production-nova-scotia',
    isExternal: true,
    imgUrl: L_RESOURCE_1_Img,
  },
  {
    id: 1,
    type: 'Domestic Green Hydrogen Uses',
    date: '10/24/2024',
    title: 'Decarbonizing Industrial Heating in Nova Scotia with Green Hydrogen',
    detail: '',
    url: 'https://netzeroatlantic.ca/outreach/webinar-series/decarbonizing-industrial-heating-nova-scotia-green-hydrogen',
    isExternal: true,
    imgUrl: L_RESOURCE_1_Img,
  },
]
